import type { Timestamp } from "@bufbuild/protobuf";
import { format } from "date-fns";

export function formatTimestamp(timestamp: Timestamp | undefined) {
  // スキーマ上は undefined のケースが多いため、利便性のため undefined の場合は空文字を返す
  if (!timestamp) {
    return "";
  }
  return format(Number(timestamp.seconds) * 1000, "yyyy年MM月dd日 HH時mm分");
}

// サーバーの時刻を取得する関数
// 時間による機能の出し分けを行うケースでは、なるべくクライアントの時計を利用したくないために利用する
export async function getCurrentTime() {
  const mockServerDate = import.meta.env.VITE_MOCK_SERVER_DATE;
  if (mockServerDate) {
    return {
      source: "mock",
      date: new Date(mockServerDate),
    };
  }

  try {
    const d = await fetch(window.location.origin, {
      method: "HEAD",
      cache: "no-cache",
    });
    const serverDateHeader = d.headers.get("Date");

    if (serverDateHeader) {
      return {
        source: "server",
        date: new Date(serverDateHeader),
      };
    }
    return {
      source: "client",
      date: new Date(),
    };
  } catch (e: unknown) {
    if (e instanceof Error) {
      return {
        source: "client",
        date: new Date(),
      };
    }

    throw e;
  }
}

/**
 * 日付文字列からJST（日本時間）のDateオブジェクトを作成する
 * @param dateString - "2025-01-18" または "2025-01-18 23:59:59" 形式の文字列
 */
export function createJSTDate(dateString: string) {
  // スペースで日付と時刻が分かれているかチェック
  const hasTime = dateString.includes(" ");

  if (hasTime) {
    // 時刻が指定されている場合はそのままJSTとして解釈
    return new Date(`${dateString.replace(" ", "T")}+09:00`);
  }

  // 時刻が指定されていない場合は00:00:00として解釈
  return new Date(`${dateString}T00:00:00+09:00`);
}
