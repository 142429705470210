import { ConnectError } from "@connectrpc/connect";

export const ERROR_CODES = {
  // Connect 関連のエラー (C001-C999)
  CONNECT_APPLY_PRIMARY_CAMPAIGN_FAILED: "C001",
  CONNECT_CREATE_MY_INVITER_FAILED: "C002",
  CONNECT_GET_MY_INVITATION_FAILED: "C003",
  CONNECT_GET_PRIMARY_CAMPAIGN_FUND_FAILED: "C004",
  CONNECT_GET_TENANT_FAILED: "C005",
  CONNECT_GET_MY_BALANCES_FAILED: "C006",
  CONNECT_GET_STORE_FAILED: "C007",
  CONNECT_GET_MY_TRANSACTION_FAILED: "C008",
  CONNECT_LOGIN_FAILED: "C009",
  CONNECT_PAY_FAILED: "C010",

  // アプリケーション全般のエラー (E001-E999)
  FAILED_TO_GET_SUBSCRIPTION_ID: "E001",
  FAILED_TO_WRITE_SUBSCRIPTION_ID: "E002",
  LOTTERY_RESULT_UNSPECIFIED: "E003",
  PROFILE_READ_FAILED: "E004",
  SDK_PERMISSION_NOT_ACCEPTED: "E005",
  SDK_PERMISSION_ERROR: "E006",
  TENANT_NOT_FOUND: "E007",
  BOUSAI_RESOURCE_READ_FAILED: "E008",
  SDK_INTERNAL_2_ERROR: "E009",
  TRANSACTION_TYPE_UNSPECIFIED: "E010",
  SDK_METHOD_NOT_SUPPORTED: "E011",
} as const;

export const ERROR_MESSAGES = {
  // Connect API関連のエラーメッセージ
  [ERROR_CODES.CONNECT_APPLY_PRIMARY_CAMPAIGN_FAILED]:
    "キャンペーンへの応募に失敗しました",
  [ERROR_CODES.CONNECT_CREATE_MY_INVITER_FAILED]:
    "友だち招待の作成に失敗しました",
  [ERROR_CODES.CONNECT_GET_MY_INVITATION_FAILED]:
    "友だち招待の取得に失敗しました",
  [ERROR_CODES.CONNECT_GET_PRIMARY_CAMPAIGN_FUND_FAILED]:
    "キャンペーンデータの取得に失敗しました",
  [ERROR_CODES.CONNECT_GET_TENANT_FAILED]: "アプリ設定情報の取得に失敗しました",
  [ERROR_CODES.CONNECT_GET_MY_BALANCES_FAILED]: "残高の取得に失敗しました",
  [ERROR_CODES.CONNECT_GET_STORE_FAILED]: "店舗情報の取得に失敗しました",
  [ERROR_CODES.CONNECT_GET_MY_TRANSACTION_FAILED]:
    "取引履歴の取得に失敗しました",
  [ERROR_CODES.CONNECT_LOGIN_FAILED]: "ログインに失敗しました",
  [ERROR_CODES.CONNECT_PAY_FAILED]: "決済に失敗しました",

  // アプリケーション全般のエラーメッセージ
  [ERROR_CODES.FAILED_TO_GET_SUBSCRIPTION_ID]:
    "アカウント情報の取得に失敗しました",
  [ERROR_CODES.FAILED_TO_WRITE_SUBSCRIPTION_ID]:
    "アカウント情報の登録に失敗しました",
  [ERROR_CODES.LOTTERY_RESULT_UNSPECIFIED]: "抽選結果が未定義です",
  [ERROR_CODES.PROFILE_READ_FAILED]: "プロフィール情報の読み取りに失敗しました",
  [ERROR_CODES.SDK_PERMISSION_NOT_ACCEPTED]:
    "ミニアプリに必要な権限が許可されていません",
  [ERROR_CODES.SDK_PERMISSION_ERROR]:
    "ミニアプリに必要な権限の取得に失敗しました",
  [ERROR_CODES.TENANT_NOT_FOUND]: "テナントが見つかりませんでした",
  [ERROR_CODES.BOUSAI_RESOURCE_READ_FAILED]:
    "防災アプリの登録が確認できませんでした",
  [ERROR_CODES.SDK_INTERNAL_2_ERROR]: "内部エラーが発生しました",
  [ERROR_CODES.TRANSACTION_TYPE_UNSPECIFIED]: "取引タイプが未定義です",
  [ERROR_CODES.SDK_METHOD_NOT_SUPPORTED]:
    "アプリの更新が必要です。更新を適用するため、アプリの再読み込みまたはストアへの移動を行います。",
} as const;

type AppErrorOptions = {
  skipSentry?: boolean;
  sdkErrorNo?: number;
  originalError?: unknown;
};

export class AppError extends Error {
  public skipSentry: boolean;
  public sdkErrorNo?: number;

  constructor(
    public code: (typeof ERROR_CODES)[keyof typeof ERROR_CODES],
    message?: string,
    options?: AppErrorOptions,
  ) {
    const errorCode = options?.sdkErrorNo
      ? `${code}-S${options.sdkErrorNo}`
      : code;

    super(
      message
        ? `${errorCode}: ${message}`
        : `${errorCode}: ${ERROR_MESSAGES[code]}`,
    );

    this.name = "AppError";
    this.skipSentry = options?.skipSentry ?? false;
    this.sdkErrorNo = options?.sdkErrorNo;

    const originalError = options?.originalError;

    // Connectの403はSentryに送信しない
    if (originalError instanceof ConnectError) {
      if (originalError.rawMessage === "permission_denied") {
        this.skipSentry = true;
      }
    }
  }
}

if (import.meta.vitest) {
  const { describe, it, expect } = import.meta.vitest;

  describe("AppError", () => {
    it("デフォルトメッセージでエラーを作成できる", () => {
      const error = new AppError(ERROR_CODES.CONNECT_LOGIN_FAILED);
      expect(error.message).toBe("C009: ログインに失敗しました");
      expect(error.code).toBe("C009");
      expect(error.name).toBe("AppError");
      expect(error.skipSentry).toBe(false);
      expect(error.sdkErrorNo).toBeUndefined();
    });

    it("カスタムメッセージでエラーを作成できる", () => {
      const error = new AppError(
        ERROR_CODES.CONNECT_LOGIN_FAILED,
        "カスタムエラーメッセージ",
      );
      expect(error.message).toBe("C009: カスタムエラーメッセージ");
    });

    it("SDKエラー番号付きでエラーを作成できる", () => {
      const error = new AppError(ERROR_CODES.CONNECT_LOGIN_FAILED, undefined, {
        sdkErrorNo: 123,
      });
      expect(error.message).toBe("C009-S123: ログインに失敗しました");
      expect(error.sdkErrorNo).toBe(123);
    });

    it("Sentryスキップオプション付きでエラーを作成できる", () => {
      const error = new AppError(ERROR_CODES.CONNECT_LOGIN_FAILED, undefined, {
        skipSentry: true,
      });
      expect(error.skipSentry).toBe(true);
    });
  });
}
