import { Link, createFileRoute } from "@tanstack/react-router";
import { css } from "../../../../styled-system/css";
import miyagiWin from "../../../assets/miyagi/win.png";
import { Card } from "../../../components/card";
import Header from "../../../components/header";
import { formatNumber } from "../../../libs/utils";

type ResultSearch = {
  wonBalance: number;
};

export const Route = createFileRoute("/_authed/lottery/additional/result")({
  validateSearch: (search: ResultSearch) => {
    return {
      wonBalance: search.wonBalance,
    };
  },
  loader: async ({ context: { tenant } }) => {
    return { tenant };
  },
  component: Result,
});

function Result() {
  const { wonBalance } = Route.useSearch();

  return (
    <>
      <Header title="みやぎポイント" />
      <div
        className={css({
          py: "24px",
          px: "20px",
          display: "flex",
          flexDir: "column",
          gap: "16px",
        })}
      >
        <Card
          header={
            <div
              className={css({
                roundedTop: "xl",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              })}
            >
              <img
                src={miyagiWin}
                alt="大当たり"
                className={css({
                  roundedTop: "xl",
                })}
              />
            </div>
          }
        >
          <div
            className={css({
              display: "flex",
              flexDirection: "column",
              gap: "20px",
            })}
          >
            <div className={css({})}>
              おめでとうございます！{formatNumber(wonBalance)}
              ポイントが付与されました！
            </div>
            <div
              className={css({
                bg: "surface.accentSuccessLight",
                rounded: "xl",
                p: "12px",
              })}
            >
              <div
                className={css({
                  color: "text.secondary",
                })}
              >
                獲得ポイント
              </div>
              <div
                className={css({
                  display: "flex",
                  alignItems: "center",
                  justifyItems: "center",
                  color: "text.accentSuccess",
                })}
              >
                <div
                  className={css({
                    display: "flex",
                    alignItems: "flex-end",
                    gap: "4px",
                    mx: "auto",
                    mt: "4px",
                  })}
                >
                  <div
                    className={css({
                      fontSize: "40px",
                      fontWeight: 400,
                      lineHeight: "100%",
                    })}
                  >
                    {formatNumber(wonBalance)}
                  </div>
                  <div
                    className={css({
                      fontSize: "16px",
                      lineHeight: "150%",
                      color: "text.secondary",
                    })}
                  >
                    pt
                  </div>
                </div>
              </div>
            </div>
            <Link
              to="/"
              className={css({
                color: "text.accentPrimary",
                textDecoration: "underline",
              })}
            >
              ポイント残高
            </Link>
          </div>
        </Card>
      </div>
    </>
  );
}
