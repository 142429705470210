import { ConnectError } from "@connectrpc/connect";
import { closeWindow, getSubscriptionIdRaw } from "@pocketsign/in-app-sdk";
import * as Sentry from "@sentry/react";
import {
  CatchBoundary,
  type ErrorComponentProps,
} from "@tanstack/react-router";
import { format } from "date-fns";
import { useCallback, useEffect, useState } from "react";
import { css } from "../../styled-system/css";
import { extractConnectErrorMessage } from "../libs/connect";
import { AppError, ERROR_CODES } from "../libs/errors";
import { sdk } from "../libs/sdk";
import { NetworkError } from "./error/network-error";
import { NotSupportedError } from "./error/not-supported-error";

interface ErrorWithSentryEventId extends Error {
  sentryEventId?: string;
}

type Props = {
  children: React.ReactNode;
};

function DataCol({
  label,
  value,
}: { label: string; value: string | null | React.ReactElement }) {
  return (
    <div
      className={css({
        display: "flex",
        flexDirection: "column",
        gap: "0",
        textAlign: "left",
      })}
    >
      <div
        className={css({
          fontSize: "14px",
          color: "text.secondary",
        })}
      >
        {label}
      </div>
      <div
        className={css({
          fontSize: "14px",
          bg: "surface.secondary",
          p: "6px",
        })}
      >
        {value}
      </div>
    </div>
  );
}

function ErrorPage({ error }: ErrorComponentProps) {
  const [subscriptionId, setSubscriptionId] = useState<string | null>(null);
  const [time, _] = useState<number>(Date.now());
  const sentryEventId = (error as ErrorWithSentryEventId).sentryEventId;

  useEffect(() => {
    try {
      getSubscriptionIdRaw(sdk)
        .then((res) => {
          setSubscriptionId(
            res.result === "success" ? res.subscriptionId : null,
          );
        })
        .catch((e) => {
          console.error(e);
        });
    } catch (e) {
      console.error(e);
    }
  }, []);

  const onClickClose = useCallback(() => {
    try {
      closeWindow(sdk);
    } catch (e) {
      console.error(e);
      window.alert("アプリケーションを再起動してください");
    }
  }, []);

  const message =
    error instanceof ConnectError
      ? extractConnectErrorMessage(error)
      : error.message;

  const clipboardText = `エラーメッセージ: ${message}\nエラーID: ${sentryEventId}\nアカウントID: ${subscriptionId}\n発生日時: ${format(time, "yyyy/MM/dd HH:mm:ss")}`;

  if (
    error instanceof AppError &&
    error.code === ERROR_CODES.SDK_METHOD_NOT_SUPPORTED
  ) {
    return <NotSupportedError onClickClose={onClickClose} />;
  }

  if (
    error.message.endsWith("[unknown] Load failed") ||
    error.message.endsWith("[unknown] Failed to fetch") ||
    error.message.match(/S100/) // SDKネットワークエラー https://www.notion.so/pocketsign/SDK-100-168ef16cba61809a908eef0eb1b73a5d?pvs=4
  ) {
    return <NetworkError onClickClose={onClickClose} />;
  }

  return (
    <div
      className={css({
        bg: "background.background",
        minH: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        px: "24px",
      })}
    >
      <section
        className={css({
          bg: "white",
          borderRadius: "16px",
          w: "full",
        })}
      >
        <div
          className={css({
            p: "24px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            textAlign: "center",
            gap: "16px",
          })}
        >
          <h2
            className={css({
              fontSize: "20px",
              fontWeight: "bold",
              color: "text.primary",
            })}
          >
            エラーが発生しました
          </h2>

          <div
            className={css({
              display: "flex",
              flexDirection: "column",
              gap: "12px",
              w: "full",
            })}
          >
            <DataCol label="エラーメッセージ" value={message} />
            <DataCol
              label="アカウントID"
              value={
                subscriptionId ? (
                  <span style={{ fontSize: "12px" }}>{subscriptionId}</span>
                ) : (
                  "不明"
                )
              }
            />
            <DataCol
              label="エラーID"
              value={
                sentryEventId ? (
                  <span style={{ fontSize: "12px" }}>{sentryEventId}</span>
                ) : (
                  "不明"
                )
              }
            />
            <DataCol
              label="発生日時"
              value={format(time, "yyyy/MM/dd HH:mm:ss")}
            />
          </div>

          <button
            type="button"
            onClick={onClickClose}
            className={css({
              bg: "surface.accentPrimary",
              color: "white",
              w: "full",
              mt: "4",
              rounded: "xl",
              p: "2",
            })}
          >
            ミニアプリを閉じる
          </button>

          <button
            type="button"
            className={css({
              bg: "surface.feedback",
              color: "white",
              w: "full",
              rounded: "xl",
              p: "2",
            })}
            onClick={() => {
              navigator.clipboard.writeText(clipboardText);
              window.alert("エラーメッセージをコピーしました");
            }}
          >
            エラーメッセージをコピーする
          </button>
        </div>
      </section>
    </div>
  );
}

export function ErrorBoundary({ children }: Props) {
  return (
    <CatchBoundary
      getResetKey={() => "reset"}
      onCatch={(error) => {
        console.error("Caught error:", error);

        try {
          // セッションストレージの問題を取り除くためクリア
          console.log("clear sessionStorage");
          sessionStorage.clear();

          // AppErrorでskipSentryがtrueの場合は送信しない
          if (error instanceof AppError && error.skipSentry) {
            console.log("Skipping Sentry for error:", error.code);
            return;
          }

          const eventId = Sentry.captureException(error);
          (error as ErrorWithSentryEventId).sentryEventId = eventId;
        } catch (e) {
          console.error("Error in error handling:", e);
        }
      }}
      errorComponent={ErrorPage}
    >
      {children}
    </CatchBoundary>
  );
}
