import { Timestamp } from "@bufbuild/protobuf";
import { Link, createFileRoute } from "@tanstack/react-router";
import { format, subMonths } from "date-fns";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  TimePaginationRequest,
  type TransactionGroup,
  Transaction_Type,
} from "schema/gen/es/chiikipoint/model/v2/model_pb";
import type { GetMyTransactionsResponse } from "schema/gen/es/chiikipoint/wallet/v2/service_pb";
import ChevronRight from "~icons/material-symbols/chevron-right";
import { css } from "../../../../styled-system/css";
import Header from "../../../components/header";
import { IconImage } from "../../../components/icon-image";
import { AppError, ERROR_CODES } from "../../../libs/errors";
import { formatNumber } from "../../../libs/utils";

type Tx = {
  id: string;
  amount: number;
  name: string;
  createdAt: Date;
  iconUrl: string;
  type: Transaction_Type;
};

type MonthlyTransactions = {
  [month: string]: Tx[];
};

export const Route = createFileRoute("/_authed/transactions/")({
  loader: async ({ context: { client } }) => {
    return { client };
  },
  component: Transactions,
});

function MonthlyTransactionsSkeleton() {
  return (
    <div>
      <div
        className={css({
          fontSize: "18px",
          marginBottom: "12px",
          pl: "16px",
          color: "text.secondary",
          width: "100px",
          height: "24px",
          background:
            "linear-gradient(110deg, #e8e8e8 8%, #f5f5f5 18%, #e8e8e8 33%)",
          backgroundSize: "200% 600%",
          animation: "1.2s shine linear infinite",
          rounded: "md",
        })}
      />
      <div
        className={css({
          bg: "white",
          rounded: "xl",
          overflow: "hidden",
        })}
      >
        {[1, 2, 3].map((d, index) => (
          <div
            key={`skeleton-${d}`}
            className={css({
              display: "flex",
              alignItems: "center",
              padding: "12px 16px",
              borderBottom: index < 2 ? "1px solid #eee" : "none",
            })}
          >
            <div
              className={css({
                width: "24px",
                height: "24px",
                borderRadius: "full",
                background:
                  "linear-gradient(110deg, #e8e8e8 8%, #f5f5f5 18%, #e8e8e8 33%)",
                backgroundSize: "200% 600%",
                animation: "1.2s shine linear infinite",
                mr: "16px",
              })}
            />
            <div className={css({ flex: 1 })}>
              <div
                className={css({
                  width: "120px",
                  height: "16px",
                  background:
                    "linear-gradient(110deg, #e8e8e8 8%, #f5f5f5 18%, #e8e8e8 33%)",
                  backgroundSize: "200% 600%",
                  animation: "1.2s shine linear infinite",
                  rounded: "md",
                  mb: "8px",
                })}
              />
              <div
                className={css({
                  width: "160px",
                  height: "14px",
                  background:
                    "linear-gradient(110deg, #e8e8e8 8%, #f5f5f5 18%, #e8e8e8 33%)",
                  backgroundSize: "200% 600%",
                  animation: "1.2s shine linear infinite",
                  rounded: "md",
                })}
              />
            </div>
            <div
              className={css({
                width: "80px",
                height: "16px",
                background:
                  "linear-gradient(110deg, #e8e8e8 8%, #f5f5f5 18%, #e8e8e8 33%)",
                backgroundSize: "200% 600%",
                animation: "1.2s shine linear infinite",
                rounded: "md",
                ml: "16px",
              })}
            />
          </div>
        ))}
      </div>
    </div>
  );
}

function MonthlyTransactionBlock({ month, txs }: { month: string; txs: Tx[] }) {
  return (
    <div>
      <div
        className={css({
          fontSize: "18px",
          marginBottom: "12px",
          pl: "16px",
          color: "text.secondary",
        })}
      >
        {month}
      </div>
      <div
        className={css({
          bg: "white",
          rounded: "xl",
          overflow: "hidden",
        })}
      >
        {txs.map((tx, index) => (
          <Link
            key={tx.id}
            className={css({
              display: "flex",
              alignItems: "center",
              padding: "12px 16px",
              borderBottom: index < txs.length - 1 ? "1px solid #eee" : "none",
              gap: "8px",
            })}
            to={`/transactions/${tx.id}`}
          >
            <div
              className={css({
                borderRadius: "full",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexShrink: 0,
              })}
            >
              <IconImage src={tx.iconUrl} alt={tx.name} size={42} />
            </div>
            <div
              className={css({
                flex: 1,
                minWidth: 0,
                pl: "8px",
              })}
            >
              <div
                className={css({
                  color: "text.primary",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                })}
              >
                {tx.name}
              </div>
              <div className={css({ fontSize: "12px", color: "#666" })}>
                {format(tx.createdAt, "yyyy年MM月dd日 HH:mm")}
              </div>
            </div>
            <div
              className={css({
                color: "text.secondary",
                flexShrink: 0,
              })}
            >
              {tx.type === Transaction_Type.REFUND
                ? "返金済み"
                : `${formatNumber(tx.amount)}pt`}
            </div>
            <ChevronRight
              className={css({
                color: "#999",
                flexShrink: 0,
              })}
            />
          </Link>
        ))}
      </div>
    </div>
  );
}

function getTransactionName(tx: TransactionGroup["transactionDetails"][0]) {
  return tx.store?.name || tx.transaction?.reason || "";
}

function baseTransaction(details: TransactionGroup["transactionDetails"]) {
  const payment = details.find(
    (d) => d.transaction?.type === Transaction_Type.PAYMENT,
  );
  if (payment) {
    return payment;
  }
  return details[0];
}

function Transactions() {
  const { client } = Route.useLoaderData();
  const [transactionGroups, setTransactionGroups] = useState<
    GetMyTransactionsResponse["transactionGroups"]
  >([]);
  const [loading, setLoading] = useState(true);

  const fetchTransactions = useCallback(
    async (pagination: TimePaginationRequest) => {
      return await client
        .getMyTransactions({
          pagination,
        })
        .catch((error) => {
          throw new AppError(
            ERROR_CODES.CONNECT_GET_MY_TRANSACTION_FAILED,
            error.message,
            {
              originalError: error,
            },
          );
        });
    },
    [client],
  );

  useEffect(() => {
    const fetchWithRetry = async (startDate: Date, retryCount = 0) => {
      const pagination = new TimePaginationRequest({
        offset: Timestamp.now(),
        limit: Timestamp.fromDate(startDate),
      });

      const res = await fetchTransactions(pagination);

      // 取得できなかったらさらに4ヶ月前のデータを取得
      // 2025年03月以降のみやポイントユーザーは、トランザクションデータが4ヶ月間存在しないケースがある
      // TODO: もっと読み込むボタンを追加する
      if (res.transactionGroups.length === 0 && retryCount < 2) {
        return fetchWithRetry(subMonths(startDate, 3), retryCount + 1);
      }

      return res;
    };

    setLoading(true);
    // 最初の3ヶ月前から開始
    fetchWithRetry(subMonths(new Date(), 4)).then((res) => {
      setTransactionGroups(res.transactionGroups);
      setLoading(false);
    });
  }, [fetchTransactions]);

  const monthlyTransactions: MonthlyTransactions = useMemo(() => {
    const map: MonthlyTransactions = {};
    const sortedGroups = transactionGroups.sort((a, b) => {
      const baseA = baseTransaction(a.transactionDetails);
      const baseB = baseTransaction(b.transactionDetails);
      return (
        Number(baseB.transaction?.createdAt?.seconds) -
        Number(baseA.transaction?.createdAt?.seconds)
      );
    });

    for (const txg of sortedGroups) {
      const month = format(
        new Date(
          Number(txg.transactionDetails[0].transaction?.createdAt?.seconds) *
            1000,
        ),
        "yyyy年MM月",
      );
      if (!map[month]) {
        map[month] = [];
      }

      const amount = txg.transactionDetails
        .filter((d) => {
          // ポイント付与とポイント利用のみを対象とする
          // Refundを算入すると、ポイント利用の金額が増えてしまうため
          // TODO: refundのamountがプラスになったら、このフィルターを削除する
          if (
            d.transaction?.type === Transaction_Type.GRANT ||
            d.transaction?.type === Transaction_Type.PAYMENT
          ) {
            return true;
          }
          return false;
        })
        .reduce((acc, ts) => acc + Number(ts.transaction?.amount || 0), 0);

      let type = txg.transactionDetails[0].transaction?.type;

      const refund = txg.transactionDetails.find(
        (d) => d.transaction?.type === Transaction_Type.REFUND,
      );
      if (refund) {
        type = Transaction_Type.REFUND;
      }

      if (!type) {
        throw new AppError(ERROR_CODES.TRANSACTION_TYPE_UNSPECIFIED);
      }

      let iconUrl = txg.transactionDetails[0].store?.iconUrl || "";

      if (!iconUrl && type === Transaction_Type.GRANT) {
        iconUrl = "/misc/grant.png";
      }

      const base = baseTransaction(txg.transactionDetails);

      map[month].push({
        id: txg.id,
        amount,
        type,
        name: getTransactionName(txg.transactionDetails[0]),
        iconUrl,
        createdAt: new Date(
          Number(base.transaction?.createdAt?.seconds) * 1000,
        ),
      });
    }
    return map;
  }, [transactionGroups]);

  return (
    <>
      <Header title="取引履歴" />
      <div
        className={css({
          p: "16px",
        })}
      >
        <div
          className={css({
            display: "flex",
            gap: "24px",
            flexDirection: "column",
          })}
        >
          {!loading && Object.keys(monthlyTransactions).length === 0 && (
            <div
              className={css({
                color: "text.secondary",
                textAlign: "center",
                mt: "16px",
              })}
            >
              取引履歴がありません
            </div>
          )}
          {loading ? (
            <>
              <MonthlyTransactionsSkeleton />
              <MonthlyTransactionsSkeleton />
            </>
          ) : (
            Object.entries(monthlyTransactions).map(([month, txs]) => (
              <MonthlyTransactionBlock key={month} month={month} txs={txs} />
            ))
          )}
        </div>
      </div>
    </>
  );
}
