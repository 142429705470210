import { openQrScanner, share } from "@pocketsign/in-app-sdk";
import { Link, createFileRoute, useNavigate } from "@tanstack/react-router";
import { QRCodeCanvas } from "qrcode.react";
import { useCallback, useMemo, useState } from "react";
import toast from "react-hot-toast";
import Copy from "~icons/material-symbols/content-copy-outline";
import QrCodeScanner from "~icons/material-symbols/qr-code-scanner";
import Share from "~icons/material-symbols/share-outline";
import Warning from "~icons/material-symbols/warning-outline";
import { css } from "../../../../styled-system/css";
import { Card } from "../../../components/card";
import Header from "../../../components/header";
import { INVITATION_END_DATE } from "../../../config";
import { getCurrentTime } from "../../../libs/date";
import { AppError, ERROR_CODES } from "../../../libs/errors";
import { getQRCodeURL } from "../../../libs/ps";
import { sdk } from "../../../libs/sdk";

export const Route = createFileRoute("/_authed/invitation/")({
  loader: async ({ context: { tenant, client } }) => {
    const { invitationCode } = await client
      .getMyInvitation({})
      .catch((error) => {
        throw new AppError(
          ERROR_CODES.CONNECT_GET_MY_INVITATION_FAILED,
          error.message,
          {
            originalError: error,
          },
        );
      });
    return { tenant, invitationCode, client };
  },
  component: Invitation,
});

export function Invitation() {
  const navigate = useNavigate();
  const { tenant, invitationCode, client } = Route.useLoaderData();
  const [code, setCode] = useState("");
  const [applyLoading, setApplyLoading] = useState(false);

  const invitationUrl = useMemo(() => {
    return getQRCodeURL(tenant.serviceId, `/i?c=${invitationCode}`);
  }, [tenant.serviceId, invitationCode]);

  const onClickReader = useCallback(() => openQrScanner(sdk), []);

  const shareText = `宮城県にお住まいの方限定！「みやポ」アプリ登録で3000円分のポイントがその場で当たるチャンス！ 招待コードはこちら【${invitationCode}】 ${invitationUrl}`;

  const onClickShare = useCallback(
    () =>
      share(sdk, {
        title: "みやぎポイント",
        text: shareText,
      }),
    [shareText],
  );

  const onClickApply = useCallback(async () => {
    if (code.length === 0) {
      return;
    }

    const currentTime = await getCurrentTime();

    if (currentTime.date > INVITATION_END_DATE) {
      toast.error("キャンペーン期間外です");
      return;
    }

    setApplyLoading(true);
    client
      .createMyInviter({ invitationCode: code })
      .then(() => {
        setCode("");
        toast.success("招待コードを適用しました");
      })
      .catch(() => {
        toast.error("招待コードが利用できません");
      })
      .finally(() => {
        setApplyLoading(false);
      });
  }, [code, client]);

  return (
    <>
      <Header
        title="友だち紹介"
        onClickBack={() => {
          navigate({ to: "/" });
        }}
      />

      <div
        className={css({
          display: "flex",
          flexDirection: "column",
          gap: "16px",
          px: "16px",
          py: "24px",
        })}
      >
        <Card>
          <>
            <div
              className={css({
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                gap: "12px",
              })}
            >
              <h1
                className={css({
                  color: "text.primary",
                  fontWeight: "600",
                  fontSize: "18px",
                })}
              >
                紹介する
              </h1>

              <QRCodeCanvas value={invitationUrl} size={140} />

              <div
                className={css({
                  display: "flex",
                  gap: "8px",
                })}
              >
                <p
                  className={css({
                    fontSize: "32px",
                  })}
                >
                  {invitationCode}
                </p>

                <button
                  type="button"
                  className={css({
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: "18px",
                  })}
                  onClick={() => {
                    navigator.clipboard.writeText(invitationCode);
                    toast.success("招待コードをコピーしました");
                  }}
                >
                  <Copy />
                </button>
              </div>
            </div>

            <button
              type="button"
              className={css({
                mt: "16px",
                w: "100%",
                py: "8px",
                px: "16px",
                bg: "gray.100",
                rounded: "xl",
                border: "1px solid",
                borderColor: "border.secondary",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "6px",
              })}
              onClick={onClickShare}
            >
              <Share />
              <span>シェア</span>
            </button>

            <div
              className={css({
                color: "text.secondary",
                bg: "surface.accentWarnLight",
                border: "1px solid",
                borderColor: "border.secondary",
                p: "16px",
                rounded: "md",
                mt: "16px",
                textAlign: "left",
                display: "flex",
                flexDirection: "column",
                gap: "8px",
              })}
            >
              <div
                className={css({
                  color: "text.primary",
                  display: "flex",
                  gap: "4px",
                })}
              >
                <Warning
                  className={css({
                    width: "24px",
                    height: "24px",
                    color: "surface.accentWarn",
                  })}
                />
                <div
                  className={css({
                    fontSize: "16px",
                  })}
                >
                  紹介キャンペーンにカウントされるには
                </div>
              </div>
              <div
                className={css({
                  fontSize: "14px",
                })}
              >
                ポケットサインアプリをダウンロードしていない場合、ダウンロード後に紹介コードの入力が必要です。
              </div>
            </div>
          </>
        </Card>

        <Card>
          <>
            <div
              className={css({
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                gap: "16px",
              })}
            >
              <h1
                className={css({
                  color: "text.primary",
                  fontWeight: "600",
                  fontSize: "18px",
                })}
              >
                紹介される
              </h1>

              <div
                className={css({
                  display: "flex",
                  gap: "8px",
                  width: "100%",
                })}
              >
                <input
                  type="text"
                  inputMode="url"
                  placeholder="招待コードを入力"
                  onChange={(e) => setCode(e.target.value)}
                  value={code}
                  className={css({
                    border: "1px solid",
                    borderColor: "border.secondary",
                    rounded: "md",
                    p: "8px",
                    px: "12px",
                    width: "100%",
                    WebkitAppearance: "none",
                    fontSize: "16px",
                  })}
                />
                <button
                  type="button"
                  className={css({
                    bg: "surface.accentPrimary",
                    color: "white",
                    rounded: "md",
                    px: "18px",
                    flexShrink: 0,
                  })}
                  onClick={onClickApply}
                  disabled={applyLoading}
                >
                  決定
                </button>
              </div>

              <button
                type="button"
                className={css({
                  w: "100%",
                  py: "8px",
                  px: "16px",
                  bg: "gray.100",
                  rounded: "xl",
                  border: "1px solid",
                  borderColor: "border.secondary",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "6px",
                })}
                onClick={onClickReader}
              >
                <QrCodeScanner />
                <span>読み取り</span>
              </button>
            </div>
          </>
        </Card>

        <Link
          to={"/invitation/detail"}
          className={css({
            color: "text.primary",
            textDecoration: "underline",
            textAlign: "center",
            pb: "32px",
          })}
        >
          キャンペーン詳細
        </Link>
      </div>
    </>
  );
}
