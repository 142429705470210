import { closeWindow } from "@pocketsign/in-app-sdk";
import { useCallback } from "react";
import { css } from "../../../styled-system/css";
import { initSDK } from "../../libs/sdk";

const serviceId = import.meta.env.VITE_CUSHION_SERVICE_ID;

export function CushionStoreNotFound() {
  const handleClickClose = useCallback(() => {
    initSDK(serviceId).then((sdk) => {
      closeWindow(sdk);
    });
  }, []);

  return (
    <div
      className={css({
        bg: "background.background",
        minH: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        px: "24px",
        gap: "36px",
      })}
    >
      <div>店舗が見つかりません</div>
      <button
        type="button"
        onClick={handleClickClose}
        className={css({
          bg: "surface.accentPrimary",
          color: "white",
          w: "full",
          mt: "4",
          rounded: "xl",
          p: "2",
        })}
      >
        ミニアプリを閉じる
      </button>
    </div>
  );
}
