import { createFileRoute, redirect, useNavigate } from "@tanstack/react-router";
import { format } from "date-fns";
import { Transaction_Type } from "schema/gen/es/chiikipoint/model/v2/model_pb";
import { css } from "../../../../styled-system/css";
import Header from "../../../components/header";
import { IconImage } from "../../../components/icon-image";
import { formatTimestamp } from "../../../libs/date";
import { AppError, ERROR_CODES } from "../../../libs/errors";
import { formatNumber } from "../../../libs/utils";

export const Route = createFileRoute("/_authed/transactions/$groupId")({
  loader: async ({ context: { client }, params: { groupId } }) => {
    const response = await client
      .getMyTransaction({
        transactionGroupId: groupId,
      })
      .catch((error) => {
        throw new AppError(
          ERROR_CODES.CONNECT_GET_MY_TRANSACTION_FAILED,
          error.message,
          {
            originalError: error,
          },
        );
      });
    if (!response.transactionGroup) {
      throw redirect({ to: "/transactions" });
    }

    const transactionGroup = response.transactionGroup;

    let type = transactionGroup.transactionDetails[0].transaction?.type;

    const refund = transactionGroup.transactionDetails.find(
      (d) => d.transaction?.type === Transaction_Type.REFUND,
    );
    if (refund) {
      type = Transaction_Type.REFUND;
    }

    if (!type) {
      throw new AppError(ERROR_CODES.TRANSACTION_TYPE_UNSPECIFIED);
    }

    return { transactionGroup, type };
  },
  component: Transaction,
});

function Cell({
  label,
  value,
  lastChild,
}: { label: string; value: string; lastChild?: boolean }) {
  return (
    <div
      className={css({
        display: "flex",
        gap: "8px",
        borderBottom: lastChild ? "none" : "1px solid",
        borderColor: "border.secondary",
        py: "16px",
      })}
    >
      <h4
        className={css({
          color: "text.primary",
          display: "flex",
          alignItems: "center",
          flexShrink: 0,
        })}
      >
        {label}
      </h4>
      <p
        className={css({
          color: "text.secondary",
          flexGrow: 1,
          textAlign: "right",
          wordBreak: "break-word",
        })}
      >
        {value}
      </p>
    </div>
  );
}

function Transaction() {
  const { transactionGroup, type } = Route.useLoaderData();
  const summaryDetail = transactionGroup.transactionDetails[0];
  const store = summaryDetail.store;
  const navigate = useNavigate();

  const reason = store
    ? `${store.name}への支払い`
    : summaryDetail.transaction?.reason;

  const amount = transactionGroup.transactionDetails.reduce(
    (acc, ts) => acc + Number(ts.transaction?.amount || 0),
    0,
  );

  const refundDetail = transactionGroup.transactionDetails.find(
    (d) => d.transaction?.type === Transaction_Type.REFUND,
  );

  const iconUrl = store?.iconUrl || "/misc/grant.png";

  const onClickBack = () => {
    navigate({ to: "/transactions" });
  };

  return (
    <>
      <Header title="取引詳細" onClickBack={onClickBack} />

      <div
        className={css({
          display: "flex",
          flexDirection: "column",
          gap: "16px",
          py: "32px",
          px: "16px",
        })}
      >
        <div
          className={css({
            display: "flex",
            gap: "16px",
          })}
        >
          <div
            className={css({
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexShrink: 0,
            })}
          >
            <IconImage src={iconUrl} alt={"店舗アイコン"} size={48} />
          </div>

          <div>
            <h1
              className={css({
                color: "text.primary",
                fontSize: "20px",
                fontWeight: 600,
                wordBreak: "break-word",
              })}
            >
              {reason}
            </h1>
            <div
              className={css({
                color: "text.secondary",
                fontSize: "14px",
                fontWeight: 400,
              })}
            >
              {format(
                Number(summaryDetail.transaction?.createdAt?.seconds) * 1000,
                "yyyy/MM/dd HH:mm",
              )}
            </div>
          </div>
        </div>

        {type === Transaction_Type.GRANT && (
          <div
            className={css({
              bg: "white",
              px: "16px",
              rounded: "16px",
            })}
          >
            <Cell label="獲得ポイント" value={`${formatNumber(amount)}pt`} />
            <Cell
              label="獲得日時"
              value={format(
                Number(summaryDetail.transaction?.createdAt?.seconds) * 1000,
                "yyyy/MM/dd HH:mm",
              )}
            />
            <Cell label="ID" value={transactionGroup.id} lastChild={true} />
          </div>
        )}

        {summaryDetail && store && (
          <div
            className={css({
              bg: "white",
              px: "16px",
              rounded: "16px",
            })}
          >
            <Cell label="店舗名" value={store.name} />
            {refundDetail ? (
              <Cell
                label="支払いポイント"
                value={`${formatNumber(refundDetail.transaction?.amount ?? 0)}pt（返金済み）`}
              />
            ) : (
              <Cell
                label="支払いポイント"
                value={`${formatNumber(-amount)}pt`}
              />
            )}
            <Cell
              label="支払い日時"
              value={formatTimestamp(summaryDetail.transaction?.createdAt)}
            />
            {refundDetail && (
              <Cell
                label="返金日時"
                value={formatTimestamp(refundDetail.transaction?.createdAt)}
              />
            )}
            <Cell label="決済ID" value={transactionGroup.id} lastChild={true} />
          </div>
        )}
      </div>
    </>
  );
}
