import { Link, createFileRoute } from "@tanstack/react-router";
import { useEffect } from "react";
import { css } from "../../../../styled-system/css";
import miyagi from "../../../assets/miyagi/icon.png";
import Header from "../../../components/header";
import { useFooter } from "../../../contexts/footer-context";

export const Route = createFileRoute("/_authed/lottery/bousai/mock")({
  component: Bousai,
});

function Bousai() {
  const { setDisabled } = useFooter();

  useEffect(() => {
    setDisabled(true);
    return () => {
      // 次画面は必ずフッター非表示のため元に戻さない
      // setDisabled(false);
    };
  }, [setDisabled]);

  return (
    <>
      <Header title="みやぎ防災" />
      <div
        className={css({
          p: "20px",
          textAlign: "center",
        })}
      >
        <h1
          className={css({
            fontSize: "24px",
            fontWeight: 500,
            color: "text.primary",
          })}
        >
          登録ありがとうございました
        </h1>

        <div
          className={css({
            bg: "white",
            rounded: "xl",
            py: "24px",
            mt: "20px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "16px",
          })}
        >
          <div
            className={css({
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            })}
          >
            <img
              src={miyagi}
              alt="logo"
              className={css({
                w: "92px",
              })}
            />
          </div>
          <p>みやぎポイント</p>
        </div>

        <Link
          to="/lottery/bousai/callback"
          className={css({
            mt: "20px",
            bg: "surface.accentPrimary",
            color: "white",
            w: "100%",
            display: "block",
            rounded: "xl",
            py: "12px",
          })}
        >
          みやぎポイントに戻る
        </Link>
      </div>
    </>
  );
}
