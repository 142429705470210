import { css } from "../../../styled-system/css";

type Props = {
  onClickClose: () => void;
};

export function NetworkError({ onClickClose }: Props) {
  return (
    <div
      className={css({
        bg: "background.background",
        minH: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        px: "24px",
      })}
    >
      <section
        className={css({
          bg: "white",
          borderRadius: "16px",
          w: "full",
        })}
      >
        <div
          className={css({
            p: "24px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            textAlign: "center",
            gap: "16px",
          })}
        >
          <h2
            className={css({
              fontSize: "20px",
              fontWeight: "bold",
              color: "text.primary",
            })}
          >
            通信エラーが発生しました
          </h2>

          <button
            type="button"
            onClick={() => {
              window.location.reload();
            }}
            className={css({
              bg: "surface.accentPrimary",
              color: "white",
              w: "full",
              mt: "4",
              rounded: "xl",
              p: "2",
            })}
          >
            再読み込み
          </button>
          <button
            type="button"
            className={css({
              bg: "surface.feedback",
              color: "white",
              w: "full",
              rounded: "xl",
              p: "2",
            })}
            onClick={onClickClose}
          >
            ミニアプリを閉じる
          </button>
        </div>
      </section>
    </div>
  );
}
