import { css } from "../../../styled-system/css";
import Header from "../header";

export function CushionLoading() {
  return (
    <>
      <Header title="ポイントアプリ選択" />
      <div
        className={css({
          bg: "background.background",
          minH: "100vh",
          pt: "48px",
          px: "24px",
          pb: "24px",
        })}
      >
        <div
          className={css({
            mt: "24px",
            height: "24px",
            bg: "surface.tertiary",
            borderRadius: "4px",
            animation: "shine 1.5s infinite linear",
            backgroundImage:
              "linear-gradient(90deg, transparent 0%, rgba(255, 255, 255, 0.4) 50%, transparent 100%)",
            backgroundSize: "200% 100%",
            width: "80%",
            mx: "auto",
          })}
        />

        <div
          className={css({
            mt: "32px",
            display: "flex",
            flexDirection: "column",
            gap: "16px",
          })}
        >
          <div
            className={css({
              height: "20px",
              width: "30%",
              bg: "surface.tertiary",
              borderRadius: "4px",
              animation: "shine 1.5s infinite linear",
              backgroundImage:
                "linear-gradient(90deg, transparent 0%, rgba(255, 255, 255, 0.4) 50%, transparent 100%)",
              backgroundSize: "200% 100%",
            })}
          />

          <div
            className={css({
              display: "flex",
              alignItems: "center",
              gap: "16px",
              border: "1px solid",
              borderColor: "border.primary",
              borderRadius: "12px",
              p: "16px",
            })}
          >
            <div
              className={css({
                width: "32px",
                height: "32px",
                bg: "surface.tertiary",
                borderRadius: "4px",
                animation: "shine 1.5s infinite linear",
                backgroundImage:
                  "linear-gradient(90deg, transparent 0%, rgba(255, 255, 255, 0.4) 50%, transparent 100%)",
                backgroundSize: "200% 100%",
              })}
            />
            <div
              className={css({
                height: "20px",
                width: "40%",
                bg: "surface.tertiary",
                borderRadius: "4px",
                animation: "shine 1.5s infinite linear",
                backgroundImage:
                  "linear-gradient(90deg, transparent 0%, rgba(255, 255, 255, 0.4) 50%, transparent 100%)",
                backgroundSize: "200% 100%",
              })}
            />
          </div>
        </div>

        <div
          className={css({
            mt: "32px",
            display: "grid",
            gridTemplateColumns: "repeat(2, 1fr)",
            gap: "16px",
          })}
        >
          {[1, 2].map((i) => (
            <div
              key={i}
              className={css({
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "8px",
                borderStyle: "solid",
                borderWidth: "1px",
                borderColor: "border.primary",
                borderRadius: "16px",
                p: "16px",
                bg: "white",
              })}
            >
              <div
                className={css({
                  width: "92px",
                  height: "92px",
                  bg: "surface.tertiary",
                  borderRadius: "16px",
                  animation: "shine 1.5s infinite linear",
                  backgroundImage:
                    "linear-gradient(90deg, transparent 0%, rgba(255, 255, 255, 0.4) 50%, transparent 100%)",
                  backgroundSize: "200% 100%",
                })}
              />
              <div
                className={css({
                  height: "20px",
                  width: "60%",
                  bg: "surface.tertiary",
                  borderRadius: "4px",
                  animation: "shine 1.5s infinite linear",
                  backgroundImage:
                    "linear-gradient(90deg, transparent 0%, rgba(255, 255, 255, 0.4) 50%, transparent 100%)",
                  backgroundSize: "200% 100%",
                })}
              />
            </div>
          ))}
        </div>

        <div
          className={css({
            height: "44px",
            bg: "surface.disable",
            w: "100%",
            borderRadius: "12px",
            mt: "32px",
          })}
        />
      </div>
    </>
  );
}
