import { css } from "../../styled-system/css";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
};

export function CampaignApplyModal({ isOpen, onClose, children }: Props) {
  if (!isOpen) {
    return null;
  }

  const handleOutsideClick = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    // クリックがバックドロップ（背景）のみであれば、閉じる処理を実行
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  return (
    <div
      className={css({
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        bg: "rgba(0, 0, 0, 0.1)",
        zIndex: 888,
      })}
    >
      {/* biome-ignore lint/a11y/useKeyWithClickEvents: <explanation> */}
      <div
        className={css({
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          zIndex: 999,
          backdropFilter: "blur(3px)",
          transition: "transform 0.3s ease-out",
          transform: "translateY(100%)", // Initially hidden
          animation: "slide-up 0.3s forwards", // Apply slide-up animation
        })}
        onClick={handleOutsideClick}
      >
        {children}
      </div>
    </div>
  );
}
