import { type Styles, css } from "../../styled-system/css";

type IconImageProps = {
  src: string;
  alt: string;
  size?: number;
  className?: Styles;
};

export function IconImage({ src, alt, size = 40, className }: IconImageProps) {
  return (
    <img
      src={src}
      alt={alt}
      loading="lazy"
      className={css({
        borderRadius: "50%",
        objectFit: "cover",
        objectPosition: "center",
        ...className,
      })}
      style={{
        width: `${size}px`,
        height: `${size}px`,
      }}
    />
  );
}
