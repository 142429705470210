type StoreQuery = {
  name?: string;
  cities: string[];
  categories: string[];
};

type Condition = {
  cities: string[];
  categories: string[];
};

export function queryBuilder({
  query: { name, cities, categories },
  condition,
}: {
  query: StoreQuery;
  condition: Condition;
}): StoreQuery {
  if (name) {
    return {
      name,
      cities: [],
      categories: [],
    };
  }

  // 検索条件がない場合は全ての市区町村を検索する
  if (cities.length === 0 && categories.length === 0) {
    return {
      cities: condition.cities,
      categories: condition.categories,
    };
  }

  return {
    cities: cities,
    categories: categories,
  };
}
