import { _internal_2 } from "@pocketsign/in-app-sdk";
import {
  type ErrorComponentProps,
  createFileRoute,
} from "@tanstack/react-router";
import { useCallback, useEffect, useState } from "react";
import { z } from "zod";
import { css } from "../../../styled-system/css";
import { CushionLoading } from "../../components/cushion/cushion-loading";
import { CushionStoreNotFound } from "../../components/cushion/cushion-store-not-found";
import Header from "../../components/header";
import { IconImage } from "../../components/icon-image";
import { CUSHION_FALLBACK_SERVICE_ID } from "../../config";
import { useAvailableTenants } from "../../hooks/cushion/use-available-tenants";
import { createCushionClient } from "../../libs/client";
import { getPaymentURL } from "../../libs/cushion";
import { getQRCodeURL } from "../../libs/ps";

const searchSchema = z.object({
  t: z.union([z.string(), z.number()]).optional(),
});

class StoreNotFoundError extends Error {
  constructor() {
    super("store not found");
  }
}

// ミニアプリ選択画面表示ロジック
// https://www.notion.so/pocketsign/129ef16cba618090bb51d2dcdb023bcb?pvs=4#155ef16cba6180c7877be90f3b86ea00
export const Route = createFileRoute("/s/$storeSlug")({
  validateSearch: (search) => searchSchema.parse(search),
  loaderDeps: ({ search: { t } }) => ({ t }),
  loader: async ({ params: { storeSlug }, deps: { t } }) => {
    const client = createCushionClient();

    // テナントが1つの場合はローディングスケルトンを表示したくないため
    // テナント一覧の取得はloaderで行う
    const response = await client.getAvailableTenants({ storeSlug });
    const { store, tenants } = response;

    // 店舗が見つからない場合、店舗が存在しない画面を表示する
    if (!store) {
      throw new StoreNotFoundError();
    }

    // ミニアプリが1つしかない場合、そのミニアプリを開く
    if (tenants.length === 1) {
      const url = getPaymentURL(tenants[0].serviceId, storeSlug, t);
      window.location.href = url;
    }

    return { tenants, store, storeSlug, t: t };
  },
  component: AppSelector,
  errorComponent: ErrorComponent,
});

function ErrorComponent({ error }: ErrorComponentProps) {
  if (error instanceof StoreNotFoundError) {
    return <CushionStoreNotFound />;
  }

  throw error;
}

function AppSelector() {
  const { store, tenants, storeSlug, t } = Route.useLoaderData();
  const [selectedServiceId, setSelectedServiceId] = useState<string | null>(
    null,
  );
  const { loading, availableTenants, error } = useAvailableTenants(tenants);

  useEffect(() => {
    if (loading) {
      return;
    }

    if (tenants.length === 1) {
      // テナントが1件の場合はloaderでリダイレクトしている
      // リダイレクトが完了した後に再リダイレクトされることを防ぐため、ここでreturnする
      return;
    }

    if (availableTenants.length === 0) {
      // 0件ならフォールバック用のトップページへ
      window.location.href = getQRCodeURL(CUSHION_FALLBACK_SERVICE_ID);
      return;
    }

    if (availableTenants.length === 1) {
      // 1件のみなら自動リダイレクト
      const tenant = availableTenants[0];
      window.location.href = getPaymentURL(tenant.serviceId, storeSlug, t);
      return;
    }
  }, [availableTenants, loading, storeSlug, t, tenants]);

  const handleSubmit = useCallback(() => {
    if (!selectedServiceId) {
      return;
    }

    window.location.href = getPaymentURL(selectedServiceId, storeSlug, t);
  }, [selectedServiceId, storeSlug, t]);

  if (loading) {
    return <CushionLoading />;
  }

  if (error) {
    throw error;
  }

  return (
    <>
      <Header title="ポイントアプリ選択" />
      <div
        className={css({
          bg: "background.background",
          overflow: "scroll",
          minH: "100vh",
          h: "100%",
          pt: "48px",
          px: "24px",
          pb: "24px",
        })}
      >
        <div
          className={css({
            mt: "24px",
            fontSize: "24px",
            textAlign: "center",
          })}
        >
          どちらのポイントアプリで
          <br />
          支払いますか？
        </div>

        <div
          className={css({
            display: "flex",
            flexDirection: "column",
            gap: "16px",
            mt: "24px",
          })}
        >
          <div
            className={css({
              color: "text.secondary",
            })}
          >
            お支払い店舗
          </div>

          <div
            className={css({
              display: "flex",
              alignItems: "center",
              gap: "16px",
              border: "1px solid",
              borderColor: "border.primary",
              borderRadius: "12px",
              p: "16px",
            })}
          >
            <div
              className={css({
                flexShrink: 0,
              })}
            >
              <IconImage src={store.iconUrl} alt={store.name} size={42} />
            </div>
            <div
              className={css({
                color: "text.primary",
                wordBreak: "break-word",
              })}
            >
              {store.name}
            </div>
          </div>
        </div>

        <div
          className={css({
            mt: "32px",
            display: "grid",
            gridTemplateColumns: "repeat(2, 1fr)",
            gap: "16px",
          })}
        >
          {availableTenants.map((tenant) => {
            const isSelected = selectedServiceId === tenant.serviceId;
            return (
              <button
                key={tenant.id}
                onClick={() => setSelectedServiceId(tenant.serviceId)}
                className={css({
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: "8px",
                  borderStyle: "solid",
                  borderWidth: "2px",
                  borderRadius: "16px",
                  p: "16px",
                  bg: "white",
                  width: "100%",
                  cursor: "pointer",
                  _hover: {
                    borderColor: "surface.accentPrimary",
                  },
                })}
                type="button"
                // cssのパフォーマンスが悪く、同時に複数ボタン押下で色が変わらないため、styleで直接指定
                style={{
                  borderColor: isSelected
                    ? "#001EFB"
                    : "rgba(156, 163, 175, 0.5)",
                  backgroundColor: isSelected ? "#eaecff" : "white",
                }}
              >
                <div
                  className={css({
                    width: "92px",
                    height: "92px",
                  })}
                >
                  <img
                    src={tenant.iconUrl}
                    alt={tenant.name}
                    className={css({
                      width: "100%",
                      height: "100%",
                      objectFit: "contain",
                      borderRadius: "16px",
                    })}
                  />
                </div>
                <div>{tenant.name}</div>
              </button>
            );
          })}
        </div>

        <button
          className={css({
            w: "100%",
            p: "12px",
            borderRadius: "12px",
            mt: "32px",
          })}
          type="button"
          disabled={!selectedServiceId}
          onClick={handleSubmit}
          style={{
            backgroundColor: selectedServiceId
              ? "#001EFB"
              : "rgba(228, 228, 231, 1)",
            color: selectedServiceId ? "white" : "rgba(156, 163, 175, 0.5)",
            cursor: selectedServiceId ? "pointer" : "not-allowed",
          }}
        >
          決定
        </button>
      </div>
    </>
  );
}
