import { getQRCodeURL } from "./ps";

export function getPaymentURL(
  serviceId: string,
  storeSlug: string,
  t?: string | number,
) {
  const tag = t && t.toString().length > 0 ? `?t=${t}` : "";
  return getQRCodeURL(serviceId, `/pay/${storeSlug}${tag}`);
}
