import { createFileRoute, useNavigate } from "@tanstack/react-router";
import { useEffect } from "react";
import { type Styles, css } from "../../../../styled-system/css";
import miyagiWin from "../../../assets/miyagi/win.png";
import Header from "../../../components/header";
import { useFooter } from "../../../contexts/footer-context";

export const Route = createFileRoute("/_authed/lottery/additional/processing")({
  loader: async ({ context: { tenant } }) => {
    return { tenant };
  },
  component: Processing,
});

const loading: Styles = {
  display: "inline-block",
  width: "100%",
  height: "100%",
  background: "linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%)",
  backgroundSize: "200% 100%",
  animation: "1.2s shine linear infinite",
};

const dot: Styles = {
  w: "20px",
  h: "20px",
  bg: "blue.400",
  rounded: "full",
  animation: "bounce 1.2s infinite ease-in-out",
};

function Processing() {
  const navigate = useNavigate();
  const { setDisabled: setFooterDisabled } = useFooter();

  useEffect(() => {
    setFooterDisabled(true);

    return () => {
      setFooterDisabled(false);
    };
  }, [setFooterDisabled]);

  // 次画面のs画像をプリロードする
  useEffect(() => {
    const img = new Image();
    img.src = miyagiWin;
  }, []);

  useEffect(() => {
    const timeout = setTimeout(() => {
      navigate({ to: "/lottery/additional/apply" });
    }, 3000);

    return () => clearTimeout(timeout);
  }, [navigate]);

  return (
    <>
      <Header title="みやぎポイント" />
      <div
        className={css({
          p: "20px",
        })}
      >
        <section
          className={css({
            bg: "white",
            pb: "28px",
            rounded: "2xl",
          })}
        >
          <div
            className={css({
              bg: "surface.disable",
              height: "112px",
              roundedTop: "2xl",
            })}
          >
            <div className={css({ ...loading, roundedTop: "2xl" })} />
            {}
          </div>
          <div
            className={css({
              display: "flex",
              flexDir: "column",
              gap: "24px",
              px: "38px",
            })}
          >
            <div
              className={css({
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "10px",
                mt: "24px",
              })}
            >
              <div className={css({ ...dot, animationDelay: "0s" })} />
              <div className={css({ ...dot, animationDelay: "0.2s" })} />
              <div className={css({ ...dot, animationDelay: "0.4s" })} />
              <div className={css({ ...dot, animationDelay: "0.6s" })} />
            </div>
            <h1
              className={css({
                textAlign: "center",
              })}
            >
              応募中...
            </h1>
            <div
              className={css({
                h: "55px",
                w: "full",
              })}
            >
              <div className={css(loading)} />
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
