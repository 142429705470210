import { Link, useNavigate } from "@tanstack/react-router";
import { useCallback, useState } from "react";
import Close from "~icons/material-symbols/close";
import Info from "~icons/material-symbols/info-outline";
import { css } from "../../styled-system/css";
import bousaiMiyapo from "../assets/miyagi/bousai-miyapo-additional.png";
import { MIYAGI_BOUSAI_SERVICE_ID } from "../config";
import type { AdditionalCampaignStatus } from "../libs/additional-campaign";
import { encodeUUID } from "../libs/ps";
import { isMockEnv } from "../libs/utils";
import { CampaignApplyModal } from "./campaign-apply-modal";

type Props = {
  additionalCampaignStatus: AdditionalCampaignStatus;
};

type ModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

function Modal({ isOpen, onClose }: ModalProps) {
  const navigate = useNavigate();

  const handleClickApplyWithBousai = useCallback(() => {
    if (isMockEnv()) {
      navigate({ to: "/lottery/bousai/mock" });
    } else {
      location.href = `https://p8n.jp/i/${encodeUUID(MIYAGI_BOUSAI_SERVICE_ID)}?u=${encodeURIComponent("#/campaign/miyagi-point")}`;
    }

    onClose();
  }, [navigate, onClose]);

  return (
    <CampaignApplyModal isOpen={isOpen} onClose={onClose}>
      <div
        className={css({
          bg: "white",
          roundedTop: "2xl",
          px: "16px",
          pt: "16px",
          pb: "48px",
          display: "flex",
          flexDir: "column",
          gap: "16px",
          position: "fixed",
          bottom: 0,
          left: 0,
          width: "100%",
          zIndex: 999,
        })}
      >
        <div>
          <div
            className={css({
              display: "flex",
            })}
          >
            <div
              className={css({
                color: "text.primary",
                fontSize: "18px",
                fontWeight: 500,
              })}
            >
              <p>ポイント付与キャンペーンに参加しましょう！</p>
            </div>

            <button
              type="button"
              className={css({
                color: "text.primary",
                border: "none",
                cursor: "pointer",
                ml: "auto",
                bg: "action.hover",
                rounded: "full",
                p: "4px",
                w: "28px",
                h: "28px",
              })}
              onClick={onClose}
            >
              <Close />
            </button>
          </div>
          <p
            className={css({
              color: "text.secondary",
              fontSize: "14px",
            })}
          >
            まずは「みやぎ防災ミニアプリ」に登録
          </p>
        </div>
        <p>
          キャンペーンへの参加は「みやぎ防災」ミニアプリの登録が必要です。登録完了後、その場で3,000ポイントがもらえます！
        </p>
        <div
          className={css({
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          })}
        >
          <img
            src={bousaiMiyapo}
            alt="防災アプリに登録"
            className={css({
              w: "176px",
            })}
          />
        </div>
        <div
          className={css({ display: "flex", gap: "10px", flexDir: "column" })}
        >
          <button
            type="button"
            className={css({
              bg: "surface.accentPrimary",
              color: "white",
              rounded: "2xl",
              p: "12px",
            })}
            onClick={handleClickApplyWithBousai}
          >
            「みやぎ防災」に登録して応募する
          </button>
          <button
            type="button"
            className={css({
              bg: "rgba(229, 231, 235, 0.50)",
              rounded: "2xl",
              p: "12px",
            })}
            onClick={onClose}
          >
            あとで
          </button>
        </div>
      </div>
    </CampaignApplyModal>
  );
}

export function AdditionalCampaignCard({ additionalCampaignStatus }: Props) {
  const [isOpen, setIsOpen] = useState(false);

  if (additionalCampaignStatus === "hidden") {
    return null;
  }

  const isDisabled = additionalCampaignStatus !== "available";

  let warningMessage: React.ReactNode | null = null;

  if (additionalCampaignStatus === "applied") {
    warningMessage = "応募済みです。";
  }

  return (
    <section
      className={css({
        bg: "surface.accentPrimaryLight",
        rounded: "xl",
        p: "16px",
        display: "flex",
        flexDir: "column",
        gap: "16px",
      })}
    >
      <div
        className={css({
          display: "flex",
          flexDir: "column",
          gap: "10px",
        })}
      >
        <div
          className={css({
            display: "flex",
            alignItems: "center",
            gap: "6px",
          })}
        >
          <Info
            className={css({
              color: "text.accentPrimary",
            })}
          />
          <p>
            令和7年2月20日までの期間、
            <br />
            ポイント付与キャンペーンを実施中！
          </p>
        </div>

        <p
          className={css({
            color: "text.secondary",
            fontSize: "14px",
          })}
        >
          「みやぎ防災」ミニアプリに登録した方全員に、その場で3,000ポイントをプレゼント！
        </p>
      </div>

      <button
        type="button"
        className={css({
          bg: "surface.accentPrimary",
          rounded: "2xl",
          px: "14px",
          py: "12px",
          color: "white",
          fontWeight: 500,
          _disabled: {
            bg: "surface.disable",
            color: "text.disable",
          },
        })}
        disabled={isDisabled}
        onClick={() => setIsOpen(true)}
      >
        応募する
      </button>
      {warningMessage && (
        <div>
          <p
            className={css({
              color: "text.accentError",
            })}
          >
            {warningMessage}
          </p>
        </div>
      )}

      <div
        className={css({
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
        })}
      >
        <Link
          to="/about-additional-campaign"
          className={css({
            flexGrow: 1,
            color: "text.accentPrimary",
            textDecoration: "underline",
          })}
        >
          キャンペーン詳細
        </Link>
      </div>
      <Modal isOpen={isOpen} onClose={() => setIsOpen(false)} />
    </section>
  );
}
