import { createFileRoute, useNavigate } from "@tanstack/react-router";
import { memo } from "react";
import { css } from "../../../styled-system/css";
import Header from "../../components/header";

export const Route = createFileRoute("/_authed/about-additional-campaign")({
  loader: async ({ context: { tenant } }) => {
    return {
      tenant,
    };
  },
  component: AboutCampaign,
});

const Heading = memo(({ children }: { children: React.ReactNode }) => (
  <h2
    className={css({
      mt: "16px",
      mb: "8px",
      fontSize: "24px",
      fontWeight: "400",
      color: "text.primary",
    })}
  >
    {children}
  </h2>
));

function AboutCampaign() {
  const navigate = useNavigate();

  return (
    <>
      <Header
        title="キャンペーン詳細"
        onClickBack={() => {
          navigate({ to: "/" });
        }}
      />

      <div
        className={css({
          p: "16px",
          pb: "64px",
        })}
      >
        <Heading>ポイントキャンペーンについて</Heading>
        <p>
          令和7年2月20日までの期間に「みやぎ防災」
          ミニアプリの利用登録をすると、3,000ポイントが即時付与されます。
          <br />
          <br />
          獲得したポイントは、令和7年2月28日まで対象店舗で1ポイント=1円として利用できます。
        </p>
        <Heading>参加の方法</Heading>
        <div
          className={css({
            display: "flex",
            flexDirection: "column",
            gap: "8px",
          })}
        >
          <h3
            className={css({
              fontWeight: "bold",
            })}
          >
            STEP1：「みやぎ防災」ミニアプリに登録
          </h3>
          <p>
            「みやぎポイント」ミニアプリホーム画面の「応募する」ボタンを押して、「みやぎ防災」ミニアプリの登録を行います。
          </p>
          <h3
            className={css({
              fontWeight: "bold",
            })}
          >
            STEP2：ポイント付与
          </h3>
          <p>
            「みやぎ防災」登録後「みやぎポイント」ミニアプリに戻ると、ポイントが直ちに付与されます。
          </p>

          <Heading>スケジュール</Heading>
          <div
            className={css({
              mt: "8px",
              display: "flex",
              flexDirection: "column",
              gap: "8px",
            })}
          >
            <div>
              <div>・ポイント付与期間</div>

              <div
                className={css({
                  px: "4",
                })}
              >
                令和7年2月3日から2月20日まで
              </div>
            </div>
            <div>
              <div>・ポイント利用期間</div>

              <div
                className={css({
                  px: "4",
                })}
              >
                <div>令和7年2月28日まで</div>
                <div>（期限を過ぎるとポイントは失効します）</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
