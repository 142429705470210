import { getCurrentGeolocation } from "@pocketsign/in-app-sdk";
import { createFileRoute } from "@tanstack/react-router";
import clsx from "clsx";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import toast from "react-hot-toast";
import {
  GetStoresRequest_Sort,
  type GetStoresResponse,
} from "schema/gen/es/chiikipoint/wallet/v2/service_pb";
import ChevronRight from "~icons/material-symbols/chevron-right";
import Close from "~icons/material-symbols/close";
import ContentCopy from "~icons/material-symbols/content-copy-outline";
import LocationOn from "~icons/material-symbols/location-on";
import OpenInNew from "~icons/material-symbols/open-in-new";
import Search from "~icons/material-symbols/search";
import Tune from "~icons/material-symbols/tune";
import { type Styles, css } from "../../../../styled-system/css";
import Header from "../../../components/header";
import { IconImage } from "../../../components/icon-image";
import { getCityAddressWithCache, sdk } from "../../../libs/sdk";
import { queryBuilder } from "../../../libs/stores";
import { trimPrefecture } from "../../../libs/utils";

const selectButtonStyle: Styles = {
  display: "flex",
  alignItems: "center",
  gap: "4px",
  bg: "white",
  rounded: "full",
  py: "8px",
  px: "16px",
  fontSize: "14px",
  border: "2px solid",
  borderColor: "border.primary",
  color: "text.primary",
  flexShrink: 0,
  "& svg": {
    width: "18px",
    height: "18px",
  },
  "&.active": {
    color: "text.accentPrimary",
    borderColor: "border.accentPrimary",
    bg: "surface.accentPrimaryLight",
  },
};

export const Route = createFileRoute("/_authed/stores/")({
  loader: async ({ context: { client, tenant } }) => {
    const { categories } = await client.getCategories({});
    const { cities } = await client.getAllCities({});

    const defaultCurrentCity =
      tenant.slug === "marumori" ? "宮城県伊具郡丸森町" : "宮城県仙台市青葉区";

    const cityAddressResource = await getCityAddressWithCache();

    let defaultCity = defaultCurrentCity;

    if (cityAddressResource && cities.includes(cityAddressResource)) {
      defaultCity = cityAddressResource;
    }

    return {
      categories,
      defaultCity,
      cities,
      client,
      tenant,
    };
  },

  component: Stores,
});

type Store = GetStoresResponse["stores"][number];

type SearchProps = {
  text: string;
  categories: string[];
  cities: string[];
};

type SearchModalProps = {
  cities: string[];
  defaultCity: string;
  categories: string[];
  isOpen: boolean;
  onClose: () => void;
  onSearch: (result: SearchProps) => void;
  shouldFocusInput: boolean;
  selectedCities: string[];
  selectedCategories: string[];
};

type StoreDetailModalProps = {
  isOpen: boolean;
  onClose: () => void;
  detail: {
    store: Store;
    companyName: string;
  } | null;
};

type GeoLocation = {
  latitude: number;
  longitude: number;
};

function StoreDetailModal({ isOpen, onClose, detail }: StoreDetailModalProps) {
  if (!isOpen) return null;

  const { store, companyName } = detail ?? {};

  if (!store || !companyName) {
    return null;
  }

  return (
    <div
      className={css({
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 1000,
        animation: "slide-up 0.2s ease-out",
        overflowY: "auto",
      })}
    >
      <div
        className={css({
          mt: "16px",
          bg: "background.background",
          rounded: "xl",
          minHeight: "calc(100% - 16px)",
          borderTop: "1px solid",
          borderColor: "border.secondary",
        })}
      >
        <header
          className={css({
            h: "48px",
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            position: "sticky",
            top: 0,
            bg: "background.background",
            zIndex: 1,
            color: "text.primary",
            roundedTop: "xl",
          })}
        >
          <h1
            className={css({
              color: "text.primary",
              fontWeight: "500",
              fontSize: "18px",
            })}
          >
            店舗詳細
          </h1>
          <button
            type="button"
            className={css({
              position: "absolute",
              right: 0,
              top: 0,
              height: "48px",
              width: "48px",
              pr: "16px",
              textAlign: "center",
            })}
            onClick={onClose}
          >
            <Close className={css({ width: "24px", height: "24px" })} />
          </button>
        </header>
        <div
          className={css({
            display: "flex",
            flexDirection: "column",
            gap: "16px",
            pt: "16px",
            px: "16px",
            pb: "calc(env(safe-area-inset-bottom, 16px) + 128px)",
          })}
        >
          <div
            className={css({
              display: "flex",
              gap: "16px",
            })}
          >
            <div
              className={css({
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                bg: "white",
                rounded: "full",
                width: "48px",
                height: "48px",
                p: "0",
                flexShrink: 0,
              })}
            >
              <IconImage src={store.iconUrl} alt={store.name} size={48} />
            </div>

            <div>
              <h1
                className={css({
                  color: "text.primary",
                  fontSize: "20px",
                  fontWeight: 600,
                  wordBreak: "break-word",
                })}
              >
                {store.name}
              </h1>
              <div
                className={css({
                  color: "text.secondary",
                  fontSize: "14px",
                  fontWeight: 400,
                })}
              >
                {store.category}
              </div>
            </div>
          </div>

          <div
            className={css({
              bg: "white",
              px: "16px",
              rounded: "16px",
            })}
          >
            <div
              className={css({
                display: "flex",
                gap: "8px",
                borderBottom: "1px solid",
                borderColor: "border.primary",
                py: "16px",
              })}
            >
              <h4
                className={css({
                  w: "80px",
                  color: "text.primary",
                  display: "flex",
                  alignItems: "center",
                  flexShrink: 0,
                })}
              >
                住所
              </h4>
              <div
                className={css({
                  display: "flex",
                  flexDirection: "column",
                  gap: "8px",
                })}
              >
                <div
                  className={css({
                    display: "flex",
                    flexGrow: 1,
                  })}
                >
                  <p
                    className={css({
                      color: "text.secondary",
                    })}
                  >
                    {store.fullAddress}
                  </p>
                  <button
                    type="button"
                    className={css({
                      ml: "12px",
                    })}
                    onClick={() => {
                      navigator.clipboard.writeText(store.fullAddress ?? "");
                      toast.success("住所をコピーしました");
                    }}
                  >
                    <ContentCopy
                      className={css({
                        w: "24px",
                        h: "24px",
                        color: "text.secondary",
                      })}
                    />
                  </button>
                </div>

                {store.location?.latitude && store.location?.longitude ? (
                  <a
                    href={`https://www.google.com/maps/search/?api=1&query=${store.location?.latitude},${store.location?.longitude}`}
                    target="_blank"
                    rel="noreferrer"
                    className={css({
                      display: "flex",
                      alignItems: "center",
                      gap: "4px",
                      color: "text.accentPrimary",
                    })}
                  >
                    Google マップ
                    <OpenInNew className={css({})} />
                  </a>
                ) : null}
              </div>
            </div>
            <div
              className={css({
                display: "flex",
                gap: "8px",
                py: "16px",
              })}
            >
              <h4
                className={css({
                  w: "80px",
                  color: "text.primary",
                  display: "flex",
                  alignItems: "center",
                  flexShrink: 0,
                })}
              >
                事業者名
              </h4>
              <p
                className={css({
                  color: "text.secondary",
                  ml: "auto",
                  wordBreak: "break-word",
                })}
              >
                {companyName}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function SearchModal({
  cities,
  defaultCity,
  categories,
  isOpen,
  onClose,
  onSearch,
  shouldFocusInput,
  selectedCities: selectedCitiesProp,
  selectedCategories: selectedCategoriesProp,
}: SearchModalProps) {
  const inputRef = useRef<HTMLInputElement>(null);
  const [searchText, setSearchText] = useState("");
  const [selectedCities, setSelectedCities] = useState(selectedCitiesProp);
  const [selectedCategories, setSelectedCategories] = useState(
    selectedCategoriesProp,
  );

  useEffect(() => {
    if (isOpen) {
      setSelectedCities(selectedCitiesProp);
      setSelectedCategories(selectedCategoriesProp);
    }
  }, [isOpen, selectedCitiesProp, selectedCategoriesProp]);

  useEffect(() => {
    if (isOpen && shouldFocusInput && inputRef.current) {
      inputRef.current.focus();

      // iOSにてフォーカス時にページがスクロールされるのを防ぐ
      setTimeout(() => {
        window.scrollTo(0, 0);
      }, 200);
    }
  }, [isOpen, shouldFocusInput]);

  if (!isOpen) return null;

  const handleCityChange = (city: string) => {
    const newSelectedCities = selectedCities.includes(city)
      ? selectedCities.filter((c) => c !== city)
      : [...selectedCities, city];
    setSelectedCities(newSelectedCities);
  };

  const handleCategoryChange = (category: string) => {
    const newSelectedCategories = selectedCategories.includes(category)
      ? selectedCategories.filter((c) => c !== category)
      : [...selectedCategories, category];
    setSelectedCategories(newSelectedCategories);
  };

  const handleSearch = () => {
    onSearch({
      text: searchText,
      categories: selectedCategories,
      cities: selectedCities,
    });
  };

  const handleClear = () => {
    setSearchText("");
    setSelectedCategories([]);
    setSelectedCities([defaultCity]);
  };

  return (
    <div
      className={css({
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 1000,
        animation: "slide-up 0.2s ease-out",
        overflowY: "auto",
      })}
    >
      <div
        className={css({
          mt: "16px",
          bg: "background.background",
          rounded: "xl",
          minHeight: "100%",
          borderTop: "1px solid",
          borderColor: "border.secondary",
        })}
      >
        <header
          className={css({
            h: "48px",
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            position: "sticky",
            top: 0,
            bg: "background.background",
            zIndex: 1,
            color: "text.primary",
            roundedTop: "xl",
          })}
        >
          <h1
            className={css({
              color: "text.primary",
              fontWeight: "500",
              fontSize: "18px",
            })}
          >
            取り扱い店舗を探す
          </h1>
          <button
            type="button"
            className={css({
              position: "absolute",
              right: 0,
              top: 0,
              height: "48px",
              width: "48px",
              pr: "16px",
              textAlign: "center",
            })}
            onClick={onClose}
          >
            <Close className={css({ width: "24px", height: "24px" })} />
          </button>
        </header>
        <div
          className={css({
            display: "flex",
            flexDirection: "column",
            gap: "16px",
            pt: "16px",
            pb: "calc(env(safe-area-inset-bottom, 16px) + 128px)",
          })}
        >
          <div
            className={css({
              display: "flex",
              gap: "16px",
              px: "16px",
            })}
          >
            <input
              ref={inputRef}
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              type="text"
              placeholder="店舗名で探す"
              className={css({
                display: "flex",
                alignItems: "center",
                gap: "8px",
                bg: "white",
                rounded: "xl",
                py: "8px",
                px: "16px",
                flexGrow: 1,
                color: "text.secondary",
                border: "1px solid",
                borderColor: "border.secondary",
              })}
            />

            <button
              type="button"
              className={css({
                display: "flex",
                alignItems: "center",
                gap: "8px",
                color: "text.secondary",
              })}
              onClick={handleClear}
            >
              クリア
            </button>
          </div>

          <div
            className={css({
              px: "16px",
              color: "text.secondary",
              fontSize: "12px",
            })}
          >
            店舗名での検索時は、選択されたカテゴリやエリアの条件は適用されません。カテゴリやエリアの条件で店舗を探す場合は、店舗名を空欄にしてください。
          </div>

          <div
            className={css({
              display: "flex",
              flexDirection: "column",
              gap: "16px",
              px: "16px",
            })}
          >
            <h3>カテゴリ選択</h3>
            <div
              className={css({
                display: "flex",
                flexWrap: "wrap",
                gap: "8px",
              })}
            >
              {categories.map((category) => (
                <button
                  type="button"
                  className={clsx(
                    css(selectButtonStyle),
                    selectedCategories.includes(category) && "active",
                  )}
                  key={category}
                  onClick={() => handleCategoryChange(category)}
                >
                  <span>{category}</span>
                </button>
              ))}
            </div>
          </div>

          {cities.length > 1 && (
            <>
              <hr
                className={css({
                  border: "1px solid",
                  borderColor: "border.secondary",
                })}
              />
              <div
                className={css({
                  display: "flex",
                  flexDirection: "column",
                  gap: "16px",
                  px: "16px",
                })}
              >
                <h3>市区町村で絞る</h3>
                <div
                  className={css({
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "8px",
                  })}
                >
                  {cities.map((city) => (
                    <label
                      key={city}
                      className={css({
                        display: "flex",
                        alignItems: "center",
                        gap: "8px",
                        cursor: "pointer",
                        width: "calc(50% - 4px)",
                      })}
                    >
                      <input
                        type="checkbox"
                        value={city}
                        checked={selectedCities.includes(city)}
                        onChange={() => handleCityChange(city)}
                      />
                      <span>{trimPrefecture(city)}</span>
                    </label>
                  ))}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      <div
        className={css({
          bg: "white",
          position: "fixed",
          bottom: 0,
          width: "100%",
          pt: "16px",
          pb: "calc(env(safe-area-inset-bottom, 16px) + 28px)",
          px: "16px",
          display: "flex",
          justifyContent: "center",
          borderTop: "1px solid",
          borderColor: "border.secondary",
        })}
      >
        <button
          type="button"
          className={css({
            bg: "surface.accentPrimary",
            color: "white",
            rounded: "xl",
            py: "12px",
            px: "16px",
            width: "100%",
          })}
          onClick={handleSearch}
        >
          検索する
        </button>
      </div>
    </div>
  );
}

function StoresSkeleton() {
  return (
    <div className={css({ px: "16px", maxWidth: "100%" })}>
      <div className={css({ bg: "white", rounded: "xl", maxWidth: "100%" })}>
        {[1, 2, 3, 4, 5].map((d, index) => (
          <div
            key={`skeleton-${d}`}
            className={css({
              display: "flex",
              alignItems: "center",
              pl: "16px",
              maxWidth: "100%",
              gap: "16px",
              width: "full",
            })}
          >
            <div
              className={css({
                width: "32px",
                height: "32px",
                borderRadius: "full",
                background:
                  "linear-gradient(110deg, #e8e8e8 8%, #f5f5f5 18%, #e8e8e8 33%)",
                backgroundSize: "200% 600%",
                animation: "1.2s shine linear infinite",
                flexShrink: 0,
              })}
            />
            <div
              className={css({
                flexGrow: 1,
                display: "flex",
                borderBottom: index === 4 ? "none" : "1px solid",
                py: "16px",
                maxWidth: "calc(100% - 48px)",
                borderColor: "border.secondary",
              })}
            >
              <div className={css({ flex: 1, minWidth: 0 })}>
                <div
                  className={css({
                    width: "120px",
                    height: "16px",
                    background:
                      "linear-gradient(110deg, #e8e8e8 8%, #f5f5f5 18%, #e8e8e8 33%)",
                    backgroundSize: "200% 600%",
                    animation: "1.2s shine linear infinite",
                    rounded: "md",
                    mb: "8px",
                  })}
                />
                <div
                  className={css({
                    width: "80px",
                    height: "12px",
                    background:
                      "linear-gradient(110deg, #e8e8e8 8%, #f5f5f5 18%, #e8e8e8 33%)",
                    backgroundSize: "200% 600%",
                    animation: "1.2s shine linear infinite",
                    rounded: "md",
                  })}
                />
              </div>
              <div
                className={css({
                  color: "text.tertiary",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  pr: "8px",
                  flexShrink: 0,
                })}
              >
                <ChevronRight strokeWidth={1} />
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

function Stores() {
  const { categories, cities, defaultCity, tenant, client } =
    Route.useLoaderData();
  const [isSearchModalOpen, setIsSearchModalOpen] = useState(false);
  const [shouldFocusInput, setShouldFocusInput] = useState(false);
  const [selectedCities, setSelectedCities] = useState<string[]>([defaultCity]);
  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
  const [stores, setStores] = useState<GetStoresResponse["stores"]>([]);
  const [searchText, setSearchText] = useState("");
  const [isStoreDetailModalOpen, setIsStoreDetailModalOpen] = useState(false);
  const [storeDetail, setStoreDetail] = useState<{
    store: Store;
    companyName: string;
  } | null>(null);
  const [loading, setLoading] = useState(true);
  const [isStoreDetailLoading, setIsStoreDetailLoading] = useState(false);
  const [location, setLocation] = useState<GeoLocation | undefined>(undefined);

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    if (!tenant.feature.storeList) return;

    client
      .getStores({
        query: queryBuilder({
          query: {
            cities: [defaultCity],
            categories: [],
          },
          condition: {
            cities: cities,
            categories: categories,
          },
        }),
        location,
        sort: location ? GetStoresRequest_Sort.DISTANCE : undefined,
      })
      .then((response) => {
        setStores(response.stores);
        setLoading(false);
      });
  }, [location]);

  useEffect(() => {
    try {
      getCurrentGeolocation(sdk, {
        enableHighAccuracy: false,
      }).then((response) => {
        if (response.result === "success") {
          setLocation({
            latitude: response.coords.latitude,
            longitude: response.coords.longitude,
          });
        }
      });
    } catch (e) {
      console.error(e);
    }
  }, []);

  const handleClickCategory = useCallback(
    (category: string) => {
      setLoading(true);
      const newSelectedCategories = selectedCategories.includes(category)
        ? selectedCategories.filter((c) => c !== category)
        : [...selectedCategories, category];
      setSelectedCategories(newSelectedCategories);
      client
        .getStores({
          query: queryBuilder({
            query: {
              cities: selectedCities,
              categories:
                newSelectedCategories.length > 0 ? newSelectedCategories : [],
            },
            condition: {
              cities: cities,
              categories: categories,
            },
          }),
          location,
          sort: location ? GetStoresRequest_Sort.DISTANCE : undefined,
        })
        .then((response) => {
          setStores(response.stores);
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [client, selectedCities, selectedCategories, location, cities, categories],
  );

  const openSearchModal = (focusInput: boolean) => {
    if (tenant.feature.storeList) {
      setIsSearchModalOpen(true);
      setShouldFocusInput(focusInput);
    }
  };

  const selectedCityText = useMemo(() => {
    if (selectedCities.length === 0) {
      return "未選択";
    }
    if (selectedCities.length === 1) {
      return trimPrefecture(selectedCities[0]);
    }

    return "複数地域";
  }, [selectedCities]);

  const handleSearch = useCallback(
    (result: SearchProps) => {
      setIsSearchModalOpen(false);
      setSearchText(result.text);
      // テキストがある場合は他の抽出条件を解除
      if (result.text.length === 0) {
        setSelectedCities(result.cities);
        setSelectedCategories(result.categories);
      }
      setLoading(true);

      client
        .getStores({
          query: queryBuilder({
            query: {
              name: result.text,
              cities: result.cities,
              categories: result.categories,
            },
            condition: {
              cities: cities,
              categories: categories,
            },
          }),
          location,
          sort: location ? GetStoresRequest_Sort.DISTANCE : undefined,
        })
        .then((response) => {
          setStores(response.stores);
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [client, location, cities, categories],
  );

  const openStoreDetailModal = useCallback(
    async (storeId: string) => {
      setIsStoreDetailLoading(true);
      try {
        const storeResponse = await client.getStore({ storeId });
        if (!storeResponse.store) {
          toast.error("店舗が見つかりません");
          setIsStoreDetailModalOpen(false);
          return;
        }
        setStoreDetail({
          store: storeResponse.store,
          companyName: storeResponse.companyName,
        });
        setIsStoreDetailModalOpen(true);
      } finally {
        setIsStoreDetailLoading(false);
      }
    },
    [client],
  );

  return (
    <>
      <Header title="取り扱い店舗" />

      <div
        className={css({
          display: "flex",
          flexDirection: "column",
          gap: "16px",
          pt: "16px",
          pb: "48px",
        })}
      >
        {!tenant.feature.storeList && (
          <div
            className={css({
              display: "flex",
              gap: "16px",
              px: "16px",
            })}
          >
            <button
              type="button"
              className={css({
                display: "flex",
                alignItems: "center",
                gap: "8px",
                bg: "surface.disable",
                rounded: "xl",
                py: "8px",
                px: "16px",
                flexGrow: 1,
                color: "text.disable",
                border: "1px solid",
                borderColor: "border.disable",
              })}
              onClick={() => openSearchModal(true)}
            >
              <Search
                className={css({
                  width: "20px",
                  height: "20px",
                })}
              />
              <span>店舗名で探す</span>
            </button>
          </div>
        )}
        {tenant.feature.storeList && (
          <div
            className={css({
              display: "flex",
              gap: "16px",
              px: "16px",
            })}
          >
            <button
              type="button"
              className={css({
                display: "flex",
                alignItems: "center",
                gap: "8px",
                bg: "white",
                rounded: "xl",
                py: "8px",
                px: "16px",
                flexGrow: 1,
                color: "text.secondary",
                border: "1px solid",
                borderColor: "border.secondary",
              })}
              onClick={() => openSearchModal(true)}
            >
              <Search
                className={css({
                  width: "20px",
                  height: "20px",
                })}
              />
              <span>{searchText.length > 0 ? searchText : "店舗名で探す"}</span>
            </button>

            <button
              type="button"
              className={css({
                display: "flex",
                alignItems: "center",
                gap: "8px",
                color: "text.secondary",
              })}
              onClick={() => openSearchModal(false)}
            >
              <Tune
                className={css({
                  width: "24px",
                  height: "24px",
                })}
              />
            </button>
          </div>
        )}

        <div
          className={css({
            display: "flex",
            gap: "8px",
            px: "16px",
            overflowX: "auto",
            scrollbarWidth: "none",
            "&::-webkit-scrollbar": {
              display: "none",
            },
          })}
        >
          {cities.length > 1 && (
            <button
              type="button"
              className={clsx(css(selectButtonStyle), "active")}
              onClick={() => openSearchModal(false)}
            >
              <LocationOn />
              <span>{searchText.length > 0 ? "全て" : selectedCityText}</span>
            </button>
          )}

          {searchText.length === 0 &&
            categories.map((category) => (
              <button
                type="button"
                className={clsx(
                  css(selectButtonStyle),
                  selectedCategories.includes(category) && "active",
                )}
                key={category}
                onClick={() => handleClickCategory(category)}
              >
                <span>{category}</span>
              </button>
            ))}
        </div>

        <div
          className={css({
            px: "16px",
            fontSize: "14px",
            color: "text.secondary",
          })}
        >
          全 {stores.length} 件
        </div>

        {!tenant.feature.storeList && (
          <div
            className={css({
              color: "text.secondary",
              textAlign: "center",
              py: "16px",
            })}
          >
            <p>準備中です</p>
            <p>しばらくお待ちください</p>
          </div>
        )}

        {tenant.feature.storeList && loading ? (
          <StoresSkeleton />
        ) : (
          tenant.feature.storeList && (
            <div className={css({ px: "16px", maxWidth: "100%" })}>
              <div
                className={css({
                  bg: "white",
                  rounded: "xl",
                  maxWidth: "100%",
                })}
              >
                {stores.map((store, index) => (
                  <button
                    type="button"
                    onClick={() => openStoreDetailModal(store.id)}
                    key={store.id}
                    className={css({
                      display: "flex",
                      alignItems: "center",
                      pl: "16px",
                      maxWidth: "100%",
                      gap: "12px",
                      width: "full",
                      textAlign: "left",
                    })}
                  >
                    <div
                      className={css({
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        flexShrink: 0,
                      })}
                    >
                      <IconImage
                        src={store.iconUrl}
                        alt={store.name}
                        size={42}
                      />
                    </div>
                    <div
                      className={css({
                        flexGrow: 1,
                        display: "flex",
                        borderBottom:
                          index === stores.length - 1 ? "none" : "1px solid",
                        py: "16px",
                        maxWidth: "calc(100% - 48px)",
                        borderColor: "border.secondary",
                      })}
                    >
                      <div
                        className={css({
                          display: "flex",
                          flexGrow: 1,
                          minWidth: 0,
                          flexDirection: "column",
                          width: "full",
                        })}
                      >
                        <h3
                          className={css({
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          })}
                        >
                          {store.name}
                        </h3>
                        <p
                          className={css({
                            color: "text.tertiary",
                            fontSize: "12px",
                          })}
                        >
                          {store.category}
                        </p>
                      </div>

                      <div
                        className={css({
                          color: "text.tertiary",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          pr: "16px",
                          flexShrink: 0,
                        })}
                      >
                        <ChevronRight
                          className={css({ marginLeft: "8px", color: "#999" })}
                        />
                      </div>
                    </div>
                  </button>
                ))}
              </div>
            </div>
          )
        )}
      </div>

      <SearchModal
        cities={cities}
        defaultCity={defaultCity}
        categories={categories}
        isOpen={isSearchModalOpen}
        onClose={() => setIsSearchModalOpen(false)}
        onSearch={handleSearch}
        shouldFocusInput={shouldFocusInput}
        selectedCities={selectedCities}
        selectedCategories={selectedCategories}
      />
      <StoreDetailModal
        isOpen={isStoreDetailModalOpen}
        onClose={() => setIsStoreDetailModalOpen(false)}
        detail={storeDetail}
      />

      {isStoreDetailLoading && (
        <div
          className={css({
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            zIndex: 2000,
          })}
        >
          <div
            className={css({
              width: "40px",
              height: "40px",
              border: "4px solid",
              borderColor: "border.secondary",
              borderTopColor: "surface.accentPrimary",
              borderRadius: "50%",
              animation: "spin 1s linear infinite",
            })}
          />
        </div>
      )}
    </>
  );
}
