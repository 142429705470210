import { readWithQrScanner } from "@pocketsign/in-app-sdk";
import { Link, useMatch, useNavigate } from "@tanstack/react-router";
import { useCallback } from "react";
import toast from "react-hot-toast";
import Article from "~icons/material-symbols/article";
import ArticleOutline from "~icons/material-symbols/article-outline";
import HomeFill from "~icons/material-symbols/home";
import QrCodeScanner from "~icons/material-symbols/qr-code-scanner";
import StoreFront from "~icons/material-symbols/storefront";
import StoreFrontOutline from "~icons/material-symbols/storefront-outline";
import { type Styles, css } from "../../styled-system/css";
import { useFooter } from "../contexts/footer-context";
import { sdk } from "../libs/sdk";

const navItemCss = css({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  textDecoration: "none",
  color: "text.primary",
  fontSize: "12px",
  pt: "8px",
  pb: "calc(env(safe-area-inset-bottom, 16px) + 8px)",
  "&.active": {
    color: "text.accentPrimary",
    fontWeight: 700,
  },
});

const navIconWrapperCss = css({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  rounded: "2xl",
  w: "56px",
  h: "32px",
  ".active &": {
    color: "text.accentPrimary",
    fontWeight: 700,
  },
});

const navIconStyle: Styles = {
  mx: "auto",
  width: "24px",
  height: "24px",
};

const navTextCss = css({
  mt: "4px",
});

const cushionServiceId = import.meta.env.VITE_CUSHION_SERVICE_ID as string;

export default function Footer() {
  const { disabled } = useFooter();
  const navigate = useNavigate();
  const isTransactionsActive = useMatch({
    from: "/_authed/transactions/",
    shouldThrow: false,
  });
  const isTransactionDetailActive = useMatch({
    from: "/_authed/transactions/$groupId",
    shouldThrow: false,
  });
  const isTransactionActive = isTransactionsActive || isTransactionDetailActive;
  const isStoreActive = useMatch({
    from: "/_authed/stores/",
    shouldThrow: false,
  });

  const scan = useCallback(async () => {
    const response = await readWithQrScanner(sdk, {
      filter: [cushionServiceId],
    });

    if (response.result === "success") {
      const { u } = response;

      if (u.startsWith("http")) {
        // 支払い用QRコードが絶対URLであるケースはないので、リダイレクタ防止のためhttpから始まる場合は許可しない
        toast.error("利用できないQRコードです");
        return;
      }

      // u=/s/{storeSlug}
      const storeSlug = u.replace("/s/", "");

      navigate({ to: `/pay/${storeSlug}` });
    }

    if (response.result === "error") {
      toast.error("QRコードの読み取りに失敗しました");
    }
  }, [navigate]);

  if (disabled) {
    return null;
  }

  return (
    <footer
      className={css({
        backgroundColor: "#FFF",
        display: "flex",
        justifyContent: "space-around",
        alignItems: "center",
        boxShadow: "0 -2px 10px rgba(0, 0, 0, 0.1)",
        position: "fixed",
        bottom: 0,
        left: 0,
        right: 0,
      })}
    >
      <Link to="/" className={navItemCss}>
        <div className={navIconWrapperCss}>
          <HomeFill className={css(navIconStyle)} />
        </div>
        <div className={navTextCss}>ホーム</div>
      </Link>
      <button type="button" className={navItemCss} onClick={scan}>
        <div className={navIconWrapperCss}>
          <QrCodeScanner className={css(navIconStyle)} />
        </div>
        <div className={navTextCss}>読み取り</div>
      </button>
      <Link to="/transactions" className={navItemCss}>
        <div className={navIconWrapperCss}>
          {isTransactionActive ? (
            <Article className={css(navIconStyle)} />
          ) : (
            <ArticleOutline className={css(navIconStyle)} />
          )}
        </div>
        <div className={navTextCss}>取引履歴</div>
      </Link>
      <Link to="/stores" className={navItemCss}>
        <div className={navIconWrapperCss}>
          {isStoreActive ? (
            <StoreFront className={css(navIconStyle)} />
          ) : (
            <StoreFrontOutline className={css(navIconStyle)} />
          )}
        </div>
        <div className={navTextCss}>店舗情報</div>
      </Link>
    </footer>
  );
}
