import { _internal_2 } from "@pocketsign/in-app-sdk";
import { useCallback, useEffect, useMemo, useState } from "react";
import type { Tenant } from "schema/gen/es/chiikipoint/model/v2/model_pb";
import { CUSHION_SERVICE_ID, TENANT_PPID_RESOURCE_ID_MAP } from "../../config";
import { AppError, ERROR_CODES } from "../../libs/errors";
import { initSDK } from "../../libs/sdk";

export function useAvailableTenants(tenants: Tenant[]) {
  const [availableTenants, setAvailableTenants] = useState<Tenant[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  const tenantPPIDResourceIds = useMemo(
    () =>
      tenants
        .map(
          (tenant) =>
            TENANT_PPID_RESOURCE_ID_MAP[
              tenant.slug as keyof typeof TENANT_PPID_RESOURCE_ID_MAP
            ],
        )
        .filter((id): id is string => !!id),
    [tenants],
  );

  const fetchAvailableTenants = useCallback(async () => {
    setLoading(true);

    try {
      const sdk = await initSDK(CUSHION_SERVICE_ID);

      const response = await _internal_2(sdk, {
        resourceIds: tenantPPIDResourceIds,
        force: true,
      });

      if (response.result === "error") {
        throw new AppError(ERROR_CODES.SDK_INTERNAL_2_ERROR);
      }

      const resolvedTenants = response.values
        .map((value) => {
          // 未サブスクの場合は value.value が null
          if (value.value === null) return null;

          // resourceId -> tenant.slug を逆引き
          const tenantSlug = Object.entries(TENANT_PPID_RESOURCE_ID_MAP).find(
            ([, id]) => id === value.id,
          )?.[0];

          return tenants.find((tenant) => tenant.slug === tenantSlug) ?? null;
        })
        .filter((tenant): tenant is Tenant => tenant !== null);

      setAvailableTenants(resolvedTenants);
    } catch (err) {
      setError(err as Error);
    } finally {
      setLoading(false);
    }
  }, [tenantPPIDResourceIds, tenants]);

  useEffect(() => {
    fetchAvailableTenants();
  }, [fetchAvailableTenants]);

  return { loading, availableTenants, error };
}
