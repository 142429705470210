import { readResourceRaw } from "@pocketsign/in-app-sdk";
import { createFileRoute, redirect } from "@tanstack/react-router";
import {
  ADDITIONAL_CAMPAIGN_START_DATE,
  MIYAGI_BOUSAI_RESOURCE_ID,
  PRIMARY_CAMPAIGN_END_DATE,
} from "../../../config";
import { login } from "../../../libs/connect";
import { getCurrentTime } from "../../../libs/date";
import { AppError, ERROR_CODES } from "../../../libs/errors";
import { sdk } from "../../../libs/sdk";

export const Route = createFileRoute("/_authed/lottery/bousai/callback")({
  loader: async ({ context: { tenant } }) => {
    const response = await readResourceRaw(sdk, {
      resourceId: MIYAGI_BOUSAI_RESOURCE_ID,
    });

    // 防災の登録が確認できない場合はエラー
    // 防災の登録が完了せずここに到達することはない
    if (response.result === "error") {
      throw new AppError(ERROR_CODES.BOUSAI_RESOURCE_READ_FAILED, undefined, {
        sdkErrorNo: response.errno,
      });
    }

    if (response.value === null || JSON.parse(response.value).length === 0) {
      throw new AppError(ERROR_CODES.BOUSAI_RESOURCE_READ_FAILED);
    }

    // 防災IDをバックエンドに登録するためログインし直す
    await login(tenant);

    const currentTime = await getCurrentTime();

    // 一次抽選終了日時より前
    if (currentTime.date <= PRIMARY_CAMPAIGN_END_DATE) {
      return redirect({
        to: "/lottery/processing",
      });
    }

    // 三次抽選開始日時より後
    if (currentTime.date >= ADDITIONAL_CAMPAIGN_START_DATE) {
      return redirect({
        to: "/lottery/additional/processing",
      });
    }

    return redirect({
      to: "/",
      state: {
        toast: {
          message: "キャンペーン期間外です",
          type: "error",
        },
      },
    });
  },
});
