import {
  ADDITIONAL_CAMPAIGN_END_DATE,
  ADDITIONAL_CAMPAIGN_START_DATE,
  MIYAGI_INVITATION_FUND_SOURCE_ID,
} from "../config";
import type { TenantWithFeature } from "./connect";

export type AdditionalCampaignStatus =
  | "hidden" // 非表示
  | "available" // 応募可能
  | "applied"; // 応募済み

type GetAdditionalCampaignStatusParams = {
  tenant: TenantWithFeature;
  balances: { fundSourceId: string }[];
  currentTime: Date;
  additionalCampaign?: {
    isApplied: boolean;
    isOver: boolean;
  };
};

export const getAdditionalCampaignStatus = ({
  tenant,
  balances,
  currentTime,
  additionalCampaign,
}: GetAdditionalCampaignStatusParams): AdditionalCampaignStatus => {
  if (!tenant.feature.lottely) {
    return "hidden";
  }

  if (tenant.slug !== "miyagi") {
    return "hidden";
  }

  // キャンペーン期間外は非表示
  if (
    currentTime < ADDITIONAL_CAMPAIGN_START_DATE ||
    ADDITIONAL_CAMPAIGN_END_DATE < currentTime
  ) {
    return "hidden";
  }

  // キャンペーン期間中で応募済みの場合は、他のbalanceに関係なく応募済み
  if (additionalCampaign?.isApplied) {
    return "applied";
  }

  // 友達紹介キャンペーン以外のbalance(一次抽選、一斉配布、配布キャンペーン)がある場合は非表示
  const hasAlreadyAppliedCampaign = balances.some(
    (balance) => balance.fundSourceId !== MIYAGI_INVITATION_FUND_SOURCE_ID,
  );

  if (hasAlreadyAppliedCampaign) {
    return "hidden";
  }

  // キャンペーン期間中で未応募の場合
  return "available";
};
